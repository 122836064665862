import { defineStore } from 'pinia'
import type { AD_KEYS, AD_POSITION, AdResponse } from '~~/src/server/modules/ad/types'

import { adApi } from '~~/src/server'

function generatePersist() {
  return [{ storage: persistedState.sessionStorage }]
}

export const useAdStore = defineStore('WEB_AD', () => {
  const AD_MAP = reactive<Map<AD_KEYS, { [key in AD_POSITION]?: AdResponse[] | undefined }>>(new Map())

  // 判断是否存在广告数据
  function hasAd(key: AD_KEYS): boolean {
    return AD_MAP.has(key) && Object.keys(AD_MAP.get(key) ?? {}).length > 0
  }
  async function getAd(key: AD_KEYS) {
    if (hasAd(key))
      return AD_MAP.get(key)
    return null
  }

  function sliceAdLength<T>(key: AD_KEYS, position: AD_POSITION, data: T[], len: number): T[] {
    // test
    const ad = AD_MAP.get(key)?.[position]
    const adLen = ad ? ad.length : 0
    return data.slice(0, len > adLen ? len - adLen : len)
  }
  return {
    AD_MAP,

    hasAd,
    getAd,
    sliceAdLength,
  }
}, {
  persist: generatePersist(),
})

export async function adLoadHandle() {
  try {
    const { data } = await adApi.getPageAds() || {}
    setAds(data)
  }
  catch (error) {}
}

export function getAdComponentData(key?: AD_KEYS, position?: AD_POSITION) {
  if (!key || !position)
    return { adData: computed(() => undefined) }

  const adStore = useAdStore()
  const { AD_MAP } = storeToRefs(adStore)
  const adData = computed(() => {
    return (key && position) ? AD_MAP.value.get(key)?.[position] : null
  })

  return {
    adData,
  }
}

export function setAds(data: AdResponse[]) {
  const adStore = useAdStore()
  const { AD_MAP } = storeToRefs(adStore)
  // Record<Partial<AD_POSITION>, AdResponse | undefined>
  data.forEach((item) => {
    if (!item.adType)
      return
    const adType = item.adType
    const typeAds = AD_MAP.value.get(adType) || {}
    const positionAds = typeAds?.[item.adLocation] || []
    positionAds.push(item)
    typeAds[item.adLocation] = positionAds
    AD_MAP.value.set(adType, typeAds)
  })
  // eslint-disable-next-line no-console
  console.log('adLoadHandle', AD_MAP.value)
}
