import { defineStore } from 'pinia'
import type { ARTICLE_TYPE } from '@qcwp/common'
import { SwipeDirection } from '@vueuse/core'
import { isArray, isClient } from '@qcwp/utils'
import { articleApi } from '~~/src/server'
import type { PaginationNuxtFn } from '~~/src/composables/usePaginationNuxt'

export interface ARTICLE_LIST_LOCATION {
  articleId: string
  currentPage: number
  pageSize: number
  type: ARTICLE_LIST_TYPE
  params: Record<string, unknown>
  list: any[]
}
export interface JumpArticle {
  id: string
  title: string
  linkUrl?: string
  type: ARTICLE_TYPE
}
export enum ARTICLE_LIST_TYPE {
  'NORMAL' = 'listArticle',
  'RECOMMEND' = 'recomArticle',
  'COLUMN' = 'columnArticle',
}
export const ARTICLE_LIST_TYPE_FN = {
  [ARTICLE_LIST_TYPE.NORMAL]: articleApi.getArticleList,
  [ARTICLE_LIST_TYPE.RECOMMEND]: articleApi.getArticleRecommendList,
  [ARTICLE_LIST_TYPE.COLUMN]: articleApi.getArticleColumnList,
} as const

export const useJumpArticleStore = defineStore('WEB_JUMP_ARTICLE', () => {
  // 当前浏览的资讯id
  const currentArticleId = ref<string | null>(null)
  // 当前浏览的资讯列表
  const preloadArticleList = ref<JumpArticle[]>([])
  // 当前浏览的资讯列表的加载类型
  const currentArticleListType = ref<ARTICLE_LIST_TYPE | null>(null)
  // 接口请求参数
  const params = ref<Record<string, unknown> | null>(null)
  // 当前每页条数
  const pageSize = ref(10)
  // 当前页码
  const currentPage = ref(1)
  // 相关分页信息
  const pagination = shallowRef<PaginationNuxtFn | null>(null)

  // 当前浏览的资讯位置
  const currentArticleIndex = computed(() => preloadArticleList.value.findIndex((v) => {
    return v?.id === currentArticleId.value
  }))

  async function getNext() {
    const haveNext = pagination.value?.canLoadMore?.() || false
    if (!haveNext || !pagination.value)
      return
    // console.log('getNext')
    const list = await getData(pagination.value.pagination.page + 1)
    transformData('next', list || [])
  }
  async function getPre() {
    const currentPage = pagination.value?.pagination?.page || 0
    const havePre = currentPage > 1
    if (!havePre || !pagination.value)
      return
    // console.log('getPre')
    const list = await getData(currentPage - 1)
    transformData('pre', list || [])
  }

  async function getData(page: number): Promise<any[]> {
    if (!pagination.value || !currentArticleListType.value)
      return []

    const fn = ARTICLE_LIST_TYPE_FN[currentArticleListType.value]
    try {
      const res = await pagination.value.loadPaginationData<any, any[]>(fn as any, params.value as any, page)
      return format(res?.list || [])
    }
    catch (error) {
      return []
    }
  }
  function transformData(type: 'next' | 'pre', data: any[]) {
    data.length = pageSize.value
    if (type === 'next') {
      // 更新下一页的数据
      preloadArticleList.value.splice(2 * pageSize.value, pageSize.value, ...data)
    }
    else if (type === 'pre') {
      // 更新上一页的数据
      preloadArticleList.value.splice(0, pageSize.value, ...data)
    }
    // checkCurrentData()
  }

  function preloadInitHandle(aheadCount = 1) {
    const direction = getCanJumpDirection(aheadCount)
    // console.log('preloadInitHandle', direction)
    if (!direction.includes(SwipeDirection.LEFT))
      getNext()
    if (!direction.includes(SwipeDirection.RIGHT))
      getPre()
  }

  function initHandle(location: ARTICLE_LIST_LOCATION) {
    if (!location)
      return
    pageSize.value = location.pageSize
    changeCurrentPage(location.currentPage)
    initPagination()

    params.value = location.params
    currentArticleId.value = location.articleId
    currentArticleListType.value = location.type

    preloadArticleList.value = new Array(3 * pageSize.value)
    preloadArticleList.value.splice(pageSize.value, pageSize.value, ...format(location.list))

    preloadInitHandle()
  }

  /**
   * 初始化分页
   * 相关分页数据不持久化，需要单独根据缓存的pageSize,currentPage初始化
   */
  function initPagination() {
    pagination.value = usePaginationNuxt({ pageNum: currentPage.value, pageSize: pageSize.value })
  }
  function clear() {
    pagination.value = null
    params.value = null
    currentArticleId.value = ''
    currentArticleListType.value = null
    preloadArticleList.value = []
  }
  // 切换页码
  function checkCurrentPage() {
    const index = currentArticleIndex.value
    // console.log(index, pageSize.value)
    if (index !== -1) {
      if (index >= 2 * pageSize.value) {
        changeCurrentPage(currentPage.value + 1)
        preloadArticleList.value.splice(0, pageSize.value)
        preloadArticleList.value.push(...new Array(pageSize.value).fill(null))
      }
      else if (index < pageSize.value) {
        changeCurrentPage(currentPage.value - 1)
        preloadArticleList.value.splice(2 * pageSize.value, pageSize.value)
        preloadArticleList.value.unshift(...new Array(pageSize.value).fill(null))
      }
      // 当上下页没有数据时加载数据
      preloadInitHandle()
    }
  }
  // 确认是否重复
  function checkCurrentData() {
    const currentArticles = preloadArticleList.value.filter(v => v?.id === currentArticleId.value)
    // console.log('checkCurrentData', currentArticles.length)
    if (currentArticles.length > 1)
      reloadPreloadArticleList()
  }
  // 重新加载列表
  async function reloadPreloadArticleList() {
    if (!currentArticleId.value || !currentArticleListType.value || !params.value)
      return
    const articles = await getData(currentPage.value)
    // console.log('reloadPreloadArticleList', articles)
    initHandle({
      type: currentArticleListType.value,
      list: articles,
      articleId: currentArticleId.value,
      currentPage: currentPage.value,
      pageSize: pageSize.value,
      params: params.value,
    })
  }
  function changeCurrentArticleId(id: string) {
    currentArticleId.value = id
  }
  function changeCurrentPage(page: number) {
    currentPage.value = page
  }
  // 能否跳转
  function canJump(id: string) {
    if (id === currentArticleId.value && currentArticleIndex.value !== -1) {
      initPagination()
      checkCurrentPage()
      // checkCurrentData()
      return true
    }

    clear()
    return false
  }

  /**
   * 根据当前缓存的文章列表来确认支持的跳转的方向
   * @returns SwipeDirection[]
   */
  function getCanJumpDirection(aheadCount = 0) {
    // console.log('getCanJumpDirection')
    const { pre, next } = getJumpId(aheadCount)

    const direction = [SwipeDirection.NONE]
    if (pre && pre.id)
      direction.push(SwipeDirection.RIGHT)
    if (next && next.id)
      direction.push(SwipeDirection.LEFT)

    return direction
  }
  /**
   * 获取前后跳转的文章信息
   */
  function getJumpId(aheadCount = 0) {
    const offset = 1 + aheadCount
    let pre = preloadArticleList.value[currentArticleIndex.value - offset]
    let next = preloadArticleList.value[currentArticleIndex.value + offset]

    if (aheadCount !== 0)
      return { pre, next }

    // console.log(pre?.id, currentArticleId.value, next?.id)
    // 暂时使用这种方式来解决，后续优化重复数据的问题
    if (pre?.id === currentArticleId.value)
      pre = preloadArticleList.value[currentArticleIndex.value - offset - 1]
    if (next?.id === currentArticleId.value)
      next = preloadArticleList.value[currentArticleIndex.value + offset + 1]

    return {
      pre,
      next,
    }
  }
  /**
   * 格式化列表数据
   */
  function format(data: any[]): JumpArticle[] {
    if (!isArray(data))
      return []
    const newData = data.map(v => ({
      title: v.title,
      id: v.id,
      type: v.articleType,
      linkUrl: v.linkUrl,
    }))
    return newData
  }

  return {
    currentArticleId,
    currentArticleIndex,
    currentArticleListType,
    params,
    pageSize,
    currentPage,
    // pagination,
    preloadArticleList,

    initHandle,
    changeCurrentArticleId,
    canJump,
    getJumpId,
    getCanJumpDirection,
    format,
  }
}, { persist: generatePersist() })

function generatePersist() {
  const clientPersist = isClient
    ? [{
        storage: localStorage,
        paths: [
          'currentArticleId',
          'currentArticleListType',
          'preloadArticleList',
          'pageSize',
          'currentPage',
          'params',

        ],
      }]
    : []

  return [
    ...clientPersist,
  ]
}
