import { defineStore } from 'pinia'
import { LogicBrand } from '~~/src/logic/car/brand'
import { LogicSeries } from '~~/src/logic/car/series'
import { LogicSeriesAll } from '~~/src/logic/car/series-all'
import { LogicModel } from '~~/src/logic/car/model'
// 车辆查询相关
export const useCarStore = defineStore('WEB_CAR', () => {
  // brand
  const {
    brands,
    formatData: formatBrandsData,
    indexList: brandsIndexList,
    serverInitHandler,
  } = LogicBrand()
  async function brandsInitHandle() {
    if (!brands.value?.length || !formatBrandsData.value || !Object.keys(formatBrandsData.value)?.length || !brandsIndexList.value?.length)
      await serverInitHandler()
    else
      return true
  }
  function getBrand(brandId: string) {
    return brands.value.find(b => b.id === brandId)
  }

  // series
  const {
    seriesAllMap,
    seriesPatternMap,
    getSeriesAllMap,
  } = LogicSeriesAll()

  // vendor series
  const {
    seriesAll,
    getCurrentBrandSeries,
    generateVenderOptions,
    serverHandler: getSeries,
  } = LogicSeries()
  async function getSeriesList(brandId: string) {
    if (!Object.keys(seriesAll.value).length || !Object.keys(seriesAll.value).includes(brandId))
      await getSeries(brandId)
    else
      return true
  }

  // model
  const { seriesModelListMap, getCurrentSeriesModel, getSeriesModelList } = LogicModel()
  async function getModelList(seriesId: string) {
    if (!seriesModelListMap.has(seriesId) || !seriesModelListMap.get(seriesId)?.count)
      return await getSeriesModelList(seriesId)
    else
      return true
  }

  return {
    // brand
    brands,
    formatBrandsData,
    brandsIndexList,
    brandsInitHandle,
    getBrand,
    // series
    seriesAllMap,
    seriesPatternMap,
    getSeriesAllMap,

    // vendor series
    seriesAll,
    getCurrentBrandSeries,
    generateVenderOptions,
    getSeriesList,

    // model
    seriesModelListMap,
    getModelList,
    getCurrentSeriesModel,
  }
})
