/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { ListState, OmitPageParam, PaginationOption, PaginationParams, PaginationResponse } from '@qcwp/common'
import { getPaginationListState } from '@qcwp/common'
import type { FetchResponseType } from '~~/src/server/request/type'

/**
 * @param key useAsyncData key prefix
 * @param fn get list data function
 * @param option pagination option
 */
export function LogicTemplateList<LIST_PARAMS extends PaginationParams, LIST_RESPONSE>(
  key: string,
  fn: (params: LIST_PARAMS) => Promise<FetchResponseType<PaginationResponse<LIST_RESPONSE[]>>>,
  option: PaginationOption = { pageSize: 12 },
  filter: (list: LIST_RESPONSE[], data: LIST_RESPONSE[]) => LIST_RESPONSE[] = (_, data) => data,
) {
  const PAGE_SIZE = option.pageSize || 12

  const list = ref<LIST_RESPONSE[]>([])
  const pagination = usePaginationNuxt({ ...option, pageSize: PAGE_SIZE })
  const state = computed<ListState>(() => getPaginationListState(pagination.pagination.status))
  function updatePageData(data: LIST_RESPONSE[]) {
    if (pagination.loadType === 'pageNumber') {
    // @ts-expect-error
      list.value = data
    }
    else if (pagination.loadType === 'up') {
      const newData = filter(list.value as LIST_RESPONSE[], data)
      // @ts-expect-error
      list.value.push(...(newData || []))
    }
  }
  async function clientHandler(params: OmitPageParam<LIST_PARAMS>, page?: number) {
    const { list } = await pagination.loadPaginationData(fn, params, page) || {}
    if (list)
      updatePageData(list)
  }
  async function serverHandler(params: OmitPageParam<LIST_PARAMS>, page?: number) {
    const asyncData = await pagination.loadPaginationAsyncData(`${key}_${qs.stringify(params)}`, fn, params, page)
    if (!asyncData)
      return
    const { data } = useNuxtAsyncHandle(asyncData) || {}
    data && updatePageData(data.list || [])
  }

  return {
    PAGE_SIZE,
    list,
    state,
    pagination,
    clientHandler,
    serverHandler,
  }
}
