import { LOAD_STATUS } from '@qcwp/common'
import { isArray } from '@qcwp/utils'
import type { BrandResponse, CarBrandParams } from '~~/src/server/modules/car/types'
import { getBrandList } from '~~/src/server/modules/car'
export interface BrandIndexList {
  [key: string]: BrandResponse[]
}
export function LogicBrand() {
  const brands = ref<BrandResponse[]>([])
  const formatData = ref<BrandIndexList | null>(null)
  const indexList = ref<string[]>([])

  const state = ref<LOAD_STATUS>(LOAD_STATUS.INIT)

  async function serverInitHandler(params?: CarBrandParams) {
    state.value = LOAD_STATUS.LOADING

    const asyncData = await useAsyncData(`BRAND_LIST_${qs.stringify(params)}`, () => getBrandList(params))
    const { data = [], error, refresh } = useNuxtAsyncHandle(asyncData)

    const { indexes, format } = formatBrands(data)
    brands.value = (data || []).map(v => ({ ...v, brandName: v.brandName.trim().replace(/\s+/g, '').replace(/\\.*?/g, '') }))
    formatData.value = format
    indexList.value = indexes

    if (!error.value)
      state.value = LOAD_STATUS.END
    else
      state.value = LOAD_STATUS.ERROR

    return { error, refresh }
  }

  async function getBrandListData(params?: CarBrandParams) {
    state.value = LOAD_STATUS.LOADING
    try {
      const { data } = await getBrandList(params)
      const { indexes, format } = formatBrands(data)
      brands.value = (data || []).map(v => ({ ...v, brandName: v.brandName.trim().replace(/\s+/g, '').replace(/\\.*?/g, '') }))
      formatData.value = format
      indexList.value = indexes
      state.value = LOAD_STATUS.END
    }
    catch (error) {
      state.value = LOAD_STATUS.ERROR
      console.error(error)
    }
  }

  function formatBrands(data: BrandResponse[]) {
    if (!data || !isArray(data))
      return { indexes: [], format: {} }

    const indexes: string[] = []
    const format: BrandIndexList = {}
    for (const brand of data) {
      const index = brand.brandNameInitial.toLocaleUpperCase()
      if (!indexes.includes(index)) {
        indexes.push(index)
        format[index] = []
      }
      if (format[index])
        format[index].push({ ...brand, brandName: brand.brandName.trim().replace(/\s+/g, '').replace(/\\.*?/g, '') })
    }
    return {
      indexes: indexes.sort((a, b) => a.localeCompare(b)),
      format,
    }
  }

  return {
    brands,
    state,
    formatData,
    indexList,
    serverInitHandler,
    getBrandListData,
  }
}
