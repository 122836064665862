import { MEDIA_MD } from '@qcwp/common'

/**
 * 头部导航高度
 */
export function NAV_HEIGHT() {
  return MEDIA_MD.value ? 56 : 48
}
/**
 * 未登录时用户名
 */
export const NO_LOGIN_TEXT = '未登录'

export const WORKER_TYPE_TEXT = {
  1: '拍摄',
  2: '主持',
}
