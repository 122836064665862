import { isBoolean } from '@qcwp/utils'
import type { _AsyncData } from 'nuxt/dist/app/composables/asyncData'
import type { FetchResponseType } from '~~/src/server/request/type'

export function useNuxtAsyncHandle<T = any>(asyncData: _AsyncData<FetchResponseType<T> | null, Error | null>, throwError = false) {
  const { data: result, error, refresh } = asyncData

  if (error.value) {
    // TODO remove or run in client
    if (isBoolean(error) && refresh)
      refresh()
    else
      recordError(error)

    if (throwError)
      throw error.value
  }

  const { data, code, msg } = result.value || {}
  return {
    result,
    error,
    refresh,
    data,
    code,
    msg,
  }
}
