import type { ArticleListParams, ArticlePagesResponse } from '~~/src/server/modules/article/type'
import { getArticleList } from '~~/src/server/modules/article'

export function LogicDealerNewArticle() {
  const newArticleData = ref<ArticlePagesResponse[]>([])
  async function serverHandler(params: ArticleListParams) {
    const asyncData = await useAsyncData(`DEALER_NEW_ARTICLE_${qs.stringify(params)}`, () => getArticleList(params))
    const { data } = useNuxtAsyncHandle(asyncData)
    newArticleData.value = data?.list || []
  }

  return {
    newArticleData,
    serverHandler,
  }
}
