import type { DealerSalesParams, DealerSalesResponse } from '~~/src/server/modules/car/types'
import { getDealerSales } from '~~/src/server/modules/car'

export function LogicDealerSales() {
  const salesData = ref<DealerSalesResponse[]>([])
  async function serverHandler(params: DealerSalesParams) {
    const asyncData = await useAsyncData('DEALER_SALES', () => getDealerSales(params))
    const { data } = useNuxtAsyncHandle(asyncData)
    salesData.value = data?.list || []
  }
  return {
    salesData,
    serverHandler,
  }
}
