import { trimStr } from '@qcwp/utils'
import type { VendorResponse } from '~~/src/server/modules/car/types'
import { getSeriesList } from '~~/src/server/modules/car'

export function LogicSeries() {
  const seriesAll = ref<{ [key: string]: VendorResponse[] }>({})
  function getCurrentBrandSeries(brandId?: string) {
    const vender = (brandId && Object.keys(seriesAll.value).includes(brandId)) ? seriesAll.value[brandId] : []
    const allSeries = []
    if (vender && vender.length) {
      for (const item of vender)
        allSeries.push(...(item?.carSeriesList || []))
    }
    return {
      vender,
      allSeries,
    }
  }
  function generateVenderOptions(brandId?: string): Record<string, string> {
    if (!brandId)
      return {}
    const options: Record<string, string> = {}
    const { vender: seriesData } = getCurrentBrandSeries(brandId)
    for (const vender of seriesData) {
      const key = vender.carVendorId
      options[key] = trimStr(vender.carVendorName)
    }
    return options
  }
  async function serverHandler(brandId: string) {
    if (!brandId)
      throw new Error(`品牌id错误:${brandId}`)
    const asyncData = await useAsyncData(`SERIES_${brandId}`, () => getSeriesList({ carBrandId: brandId }))
    if (!asyncData)
      return
    const { data = [], error, refresh } = useNuxtAsyncHandle(asyncData)
    seriesAll.value[brandId] = data

    return { error, refresh }
  }

  return {
    seriesAll,
    getCurrentBrandSeries,
    generateVenderOptions,
    serverHandler,
  }
}
