import type { ARTICLE_TYPE, BANNER_DATA_TYPE } from '@qcwp/common'
import { LOAD_STATUS } from '@qcwp/common'
import { isArray } from '@qcwp/utils'
import { getArticleBanner } from '~~/src/server/modules/article'
import type {
  ArticleBannerParams,
  ArticleBannerResponse,
} from '~~/src/server/modules/article/type'

export interface SwiperItem {
  img: string
  title: string
  type: BANNER_DATA_TYPE
  articleType?: ARTICLE_TYPE
  id?: string
  url?: string
  watermark?: string | null
}

export function LogicSwiper() {
  const state = ref(LOAD_STATUS.INIT)
  const swiperList = ref<{ raw: ArticleBannerResponse[] | null; format: SwiperItem[] }>({
    raw: null,
    format: [],
  })

  async function getData(params: ArticleBannerParams) {
    if (!params.bannerShowLocation)
      return null
    return await getArticleBanner(params)
  }
  async function clientHandler(params: ArticleBannerParams) {
    try {
      const { data = [] } = await getData(params) || {}
      state.value = LOAD_STATUS.END
      swiperList.value = { raw: data, format: formatSwiper(data) }
    }
    catch (error) {
      state.value = LOAD_STATUS.ERROR
      recordError(error)
    }
  }
  async function serverInitHandler(params: ArticleBannerParams) {
    const asyncData = await useAsyncData(`SWIPER_${qs.stringify(params)}`, () => getData(params))
    const { data = [], error } = useNuxtAsyncHandle(asyncData)
    if (error.value)
      state.value = LOAD_STATUS.ERROR
    else
      state.value = LOAD_STATUS.END

    swiperList.value = { raw: data, format: formatSwiper(data) }
  }
  return {
    swiperList,
    state,
    clientHandler,
    serverInitHandler,
  }
}

function formatSwiper(res: ArticleBannerResponse[]): SwiperItem[] {
  if (!res || !isArray(res) || !res.length)
    return []

  const data: SwiperItem[] = res.map(item => ({
    img: item.cover,
    title: item.title,
    type: item.dataType,
    articleType: item.articleType,
    id: item.dataId,
    url: item.linkUrl,
    watermark: item.watermark,
  }))

  return data
}
