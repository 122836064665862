export interface SiteConfig {
  title: string
  description: string
  keywords: string[]
}

export const DEFAULT_TITLE = '汽车网评 - 提供个性化汽车资讯'
export const DEFAULT_DESCRIPTION = '汽车网评 - 提供个性化汽车资讯，为您提供汽车行业最新动态报道，试驾体检分享，汽车问题深度点评，汽车大事件报道，汽车参数，汽车报价，汽车经销商，商家促销活动等信息。'
export const DEFAULT_KEYWORDS = ['汽车资讯', '汽车新闻', '汽车视频', '新车试驾', '汽车报价', '汽车参数', '汽车测评', '最新报价', '汽车销量', '汽车发布会', '车展', '汽车行情', '汽车经销商', '汽车促销活动', '汽车大事件', '汽车行业', '汽车网评', '汽车网评网', '汽车网评网评号', '汽车网评号', '汽车网评号人', '汽车网评号机构', '汽车网评号平台', '汽车网评号传播', '汽车网评号新闻', '汽车网评号资讯', '汽车网评号视频', '懂车帝', '汽车头条', '汽车之家', '网评号']

// 首页
export const HOME_SITE_META: SiteConfig = {
  title: DEFAULT_TITLE,
  description: DEFAULT_DESCRIPTION,
  keywords: [...DEFAULT_KEYWORDS],
}

// 网评号首页
export const MEDIA_HOME_SITE_META: SiteConfig = {
  title: '汽车网评号 - 汽车网评',
  description: '汽车网评网评号是一个开放的平台，欢迎更多优秀的汽车行业独立网评号人和机构加入到平台中来。',
  keywords: ['汽车网评号', '网评号新闻', '汽车网评号平台', '汽车网评号机构', '汽车网评号新闻', '汽车网评号资讯', '汽车网评号视频', '懂车号', '头条号', '百家号', '车家号', '网评号传播'],
}
export const MEDIA_DETAIL_SITE_META: SiteConfig = {
  title: '网评号详情 - 汽车网评',
  description: MEDIA_HOME_SITE_META.description,
  keywords: [...MEDIA_HOME_SITE_META.keywords],
}

// 资讯首页
export const NEWS_HOME_SITE_META: SiteConfig = {
  title: '资讯频道 - 汽车网评',
  description: '资讯频道力求传播全面、精准、及时的汽车资讯。下含汽车动态、厂商新闻、汽车测评、行情导购、热点专题及汽车召回等，是您了解汽车新闻的优选网络平台。',
  keywords: ['汽车新闻', '汽车资讯', '最新汽车资讯', '最新汽车新闻', '行业', '测评', '厂商', '导购', '召回'],
}
export const NEWS_DETAIL_SITE_META: SiteConfig = {
  title: '图文详情 - 汽车网评',
  description: DEFAULT_DESCRIPTION,
  keywords: [...NEWS_HOME_SITE_META.keywords],
}

export const VIDEO_HOME_SITE_META: SiteConfig = {
  title: '视频频道 - 汽车网评',
  description: '视频频道力求分享优质的汽车试驾、汽车测评、汽车科普、新车讲解等相关视频。',
  keywords: ['汽车新闻', '汽车资讯', '最新汽车资讯', '最新汽车新闻', '汽车行业动态', '汽车测评', '汽车讲解', '汽车试驾'],
}
export const VIDEO_DETAIL_SITE_META: SiteConfig = {
  title: '视频详情 - 汽车网评',
  description: VIDEO_HOME_SITE_META.description,
  keywords: [...VIDEO_HOME_SITE_META.keywords],
}

export const LIVE_HOME_SITE_META: SiteConfig = {
  title: '直播频道 - 汽车网评',
  description: '直播频道力求传播全面、精准、及时的汽车发布会以及汽车相关的直播内容。',
  keywords: ['汽车发布会', '汽车发布会直播', '汽车直播', '汽车资讯', '最新汽车资讯', '最新汽车新闻'],
}
export const LIVE_DETAIL_SITE_META: SiteConfig = {
  title: '直播详情 - 汽车网评',
  description: DEFAULT_DESCRIPTION,
  keywords: [...LIVE_HOME_SITE_META.keywords],
}

export const NEWS_HANG_QING_SITE_META: SiteConfig = {
  title: '行情导购 - 汽车网评',
  description: '汽车网评行情栏目为您提供全国各地的最新车市信息，包括汽车降价信息、促销信息、厂商活动信息，您可以在车市行情看到SUV、大型车、中级车、小型车等各档次车型的降价促销信息，帮你用最低价购得新车。',
  keywords: ['汽车降价', '新车降价', '车市行情', '汽车价格', '新车价格', '汽车促销', '汽车优惠', '优惠购车', '底价购车', '低价买车', '清库车', '新车到店', '新车促销', '汽车降价新闻', '汽车降价排行', '车市动态', '车市销量', 'SUV降价', '大型车降价', 'SUV行情', '大型车行情'],
}
// 经销商详情
export const DEALER_DETAIL_SITE_META: SiteConfig = {
  title: '经销商详情 - 汽车网评',
  description: '提供全国各地的汽车经销商信息，包括汽车经销商地址、电话、经销商活动信息，您可以在汽车经销商看到汽车经销商的详细信息，帮你用最低价购得新车。',
  keywords: ['汽车新闻', '汽车资讯', '最新汽车资讯', '最新汽车新闻', '经销商', '厂商', '导购', '召回'],
}

export const SERIES_DETAIL_SITE_META: SiteConfig = {
  title: '车系详情 - 汽车网评',
  description: '车系详情',
  keywords: ['汽车新闻', '汽车资讯', '最新汽车资讯', '最新汽车新闻', '经销商', '厂商', '车系'],
}

export const CAR_TEST_DRIVE_SITE_META: SiteConfig = {
  title: '邀约试驾 - 汽车网评',
  description: '车系详情',
  keywords: ['汽车新闻', '汽车资讯', '最新汽车资讯', '最新汽车新闻', '经销商', '厂商', '车系'],
}

export const KOUBEI_SITE_META: SiteConfig = {
  title: '汽车口碑 - 汽车网评',
  description: '想了解车主们怎么点评爱车，就来汽车网评点评！',
  keywords: ['车主点评', '车系口碑', '汽车网评', '汽车口碑', '汽车好评口碑', '汽车差评口碑', '汽车口碑评分排行', '全部口碑排行', '维度口碑排行', '短口碑排行', '最满意口碑排行', '最不满意口碑排行', '外观口碑排行', '内饰口碑排行', '空间口碑排行', '动力口碑排行', '操控口碑排行', '能耗口碑排行', '舒适性口碑排行', '性价比口碑排行'],
}

export const TOPIC_DETAIL_SITE_META: SiteConfig = {
  title: '专题详情 - 汽车网评',
  description: DEFAULT_DESCRIPTION,
  keywords: [...DEFAULT_KEYWORDS],
}
