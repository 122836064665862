import { defineStore } from 'pinia'
import { removeElementTags } from '@qcwp/utils'
import { LogicSwiper } from '~/logic/swiper'
import { DEFAULT_DESCRIPTION, DEFAULT_KEYWORDS } from '~~/src/common/site'
import { BannerShowLocation } from '~~/src/server/modules/article/type'
import { LogicDealerBaseInfo } from '~~/src/logic/dealer/base-info'
import { LogicDealerModel } from '~~/src/logic/dealer/model'
import { LogicDealerSales } from '~~/src/logic/dealer/sales'
import { LogicDealerSeriesAll } from '~~/src/logic/dealer/series-all'
import { LogicDealerNewArticle } from '~~/src/logic/dealer/new-article'

export const useDealerStore = defineStore('WEB_DEALER', () => {
  const dealerId = ref('')
  // dealer base info
  const { dealerDetail, serverHandler: getDealerDetailReq } = LogicDealerBaseInfo()
  const canLoadArticle = computed(() =>
    dealerDetail.value?.existBindMedia
  || (!!dealerDetail.value?.mediaUserId && dealerDetail.value?.mediaUserId !== '0'),
  )
  const dealerMediaUserId = computed(() => {
    if (!dealerDetail.value)
      return ''
    return dealerDetail.value.mediaUserId
  })
  // base site
  const title = computed(() => {
    if (!dealerDetail.value)
      return '经销商详情 - 汽车网评'
    return `${dealerDetail.value.dealerName} - 汽车网评`
  })
  const keywords = computed(() => {
    if (!dealerDetail.value)
      return DEFAULT_KEYWORDS
    const { dealerName, dealerNameSimple, majorCarName, provinceName, cityName, address } = dealerDetail.value
    return [dealerName, dealerNameSimple, majorCarName, provinceName, cityName, address].filter(v => !!v)
  })
  const description = computed(() => {
    if (!dealerDetail.value)
      return DEFAULT_DESCRIPTION
    return removeElementTags(dealerDetail.value.dealerIntro)
  })
  // check
  const isSame = computed(() => {
    if (!dealerDetail.value)
      return false
    return dealerId.value === dealerDetail.value.id
  })
  // swiper
  const { swiperList, serverInitHandler: getSwiperListReq } = LogicSwiper()
  // 主推车型
  const { modelData: modelMainRecommendRawData, formatModelData: modelMainRecommendData, serverHandler: getDealerModelReq } = LogicDealerModel('主推车型')
  // 最新促销车型
  const { modelData: modelPromotionRawData, formatModelData: modelPromotionData, serverHandler: getDealerModelPromotionReq } = LogicDealerModel('最新促销车型')
  // 销售顾问
  const { salesData, serverHandler: getDealerSalesReq } = LogicDealerSales()
  // 最新资讯
  const newArticleParams = computed(() => ({ mediaUserId: dealerMediaUserId.value, pageNum: 1, pageSize: 10 }))
  const { newArticleData, serverHandler: getDealerArticleReq } = LogicDealerNewArticle()

  // 经营品牌
  const { formatData: allManageVendor, serverHandler: getDealerSeriesAllReq } = LogicDealerSeriesAll()
  // get data
  async function getDealerDetail(id: string) {
    await getDealerDetailReq(id)
  }
  async function getSwiper(id: string) {
    if (isSame.value && id === dealerId.value && swiperList.value.format.length)
      return true
    else
      await getSwiperListReq({ carDealerId: id, bannerShowLocation: BannerShowLocation.DEALER, pageNum: 1, pageSize: 8 })
  }
  async function getDealerMainRecommendModel(id: string) {
    if (isSame.value && id === dealerId.value && modelMainRecommendData.value.length)
      return true
    else
      await getDealerModelReq({ isMainRecomm: true, carDealerId: dealerId.value })
  }
  async function getDealerModelPromotion(id: string) {
    if (isSame.value && id === dealerId.value && modelPromotionData.value.length)
      return true
    else
      await getDealerModelPromotionReq({ isPromotion: true, carDealerId: dealerId.value })
  }
  async function getDealerSales(id: string) {
    if (isSame.value && id === dealerId.value && salesData.value.length)
      return true
    else
      await getDealerSalesReq({ carDealerId: dealerId.value, pageNum: 1, pageSize: 5 })
  }
  async function getDealerSeriesAll(id: string) {
    if (isSame.value && id === dealerId.value && allManageVendor.value.length)
      return true
    else
      await getDealerSeriesAllReq(dealerId.value)
  }
  async function getDealerArticle() {
    if (isSame.value && newArticleData.value.length)
      return true
    else
      await getDealerArticleReq(newArticleParams.value)
  }

  function reset() {
    dealerDetail.value = null
    // swiper
    swiperList.value = { raw: null, format: [] }
    // 主推车型
    modelMainRecommendRawData.value = []
    // 最新促销车型
    modelPromotionRawData.value = []
    // 销售顾问
    salesData.value = []
    // 最新资讯
    newArticleData.value = []
    // 经营品牌
    allManageVendor.value = []
  }
  return {
    // site
    title,
    keywords,
    description,

    canLoadArticle,
    dealerMediaUserId,
    dealerId,
    // dealer base info
    dealerDetail,
    // swiper
    swiperList,
    // check
    isSame,
    // 销售顾问
    salesData,
    // 主推车型
    modelMainRecommendData,
    // 最新促销车型
    modelPromotionData,
    // 最新资讯
    newArticleData,
    // 经营品牌
    allManageVendor,
    // get data
    getDealerDetail,
    getSwiper,
    getDealerMainRecommendModel,
    getDealerModelPromotion,
    getDealerSales,
    getDealerSeriesAll,
    getDealerArticle,

    reset,
  }
})
