// 每天精选
import type { PaginationOption } from '@qcwp/common'
import { ARTICLE_TYPE } from '@qcwp/common'
import { timeFormat } from '@qcwp/utils'
import { LogicTemplateList } from '../common'
import { addDailyData, createDaily, deleteDailyData, getDailyDetail, getDailyList, updateDailyData } from '~~/src/server/modules/article'
import type { DailyCreateParams, DailyEssenceArticleDataRefVO, DailyEssenceResponse, DailyItemData } from '~~/src/server/modules/article/type'
import { useGetRouteQuery } from '~~/src/common/function/url'

export function LogicDailyList(option: PaginationOption = { pageSize: 12 }) {
  return LogicTemplateList('DAILY_LIST', getDailyList, option)
}

export interface DailyEssenceFormatData extends Omit<DailyEssenceResponse, 'essenceArticleRefVOList' | ''> {
  articles: DailyEssenceArticleDataRefVO[]
}
export function formatEssenceData(data: DailyEssenceResponse) {
  const newData: any = { ...data }
  delete newData.essenceDataRefVOList
  const articles = []
  if (data.essenceDataRefVOList?.length) {
    for (const item of data.essenceDataRefVOList) {
      articles.push({
        ...item,
        articleType: item.articleType || (item.dataRefType === '4' ? ARTICLE_TYPE.LIVE : ARTICLE_TYPE.PICTURE),
      })
    }
  }

  newData.articles = articles.sort((a, b) => (Number(a.sort) || 0) - (Number(b.sort) || 0))

  return newData as DailyEssenceFormatData
}

export function LogicDailyDetail() {
  const id = useGetRouteQuery('id', 'string', '')
  const status = ref<'SUCCESS' | 'NULL' | 'FAIL' | 'INIT'>('INIT')
  const currentEssence = ref<DailyEssenceFormatData | null>(null)

  const essenceId = computed(() => currentEssence.value?.id || '')
  const currentMaxSort = computed(() => {
    if (!currentEssence.value?.articles?.length)
      return 0
    return Math.max(...currentEssence.value.articles.map(item => Number(item.sort) || 0))
  })
  const todayDailyCreated = ref(false)
  const today = timeFormat()

  async function serverHandler(id: string) {
    currentEssence.value = null
    status.value = 'INIT'
    const asyncData = await useAsyncData(`DAILY_DETAIL_${id}`, () => getDailyDetail(id))
    const { code, data, error } = useNuxtAsyncHandle(asyncData)

    if (error?.value)
      return status.value = 'FAIL'

    if (code === 200 && data) {
      status.value = 'SUCCESS'
      currentEssence.value = formatEssenceData(data)
    }
    else {
      status.value = 'NULL'
    }
  }

  async function checkCreatedDaily() {
    try {
      const { code, data } = await getDailyDetail()

      if (code === 200 && data && timeFormat(data.numDate) === today) {
        todayDailyCreated.value = true
        status.value = 'SUCCESS'
        currentEssence.value = formatEssenceData(data)
      }
      else {
        status.value = 'NULL'
        todayDailyCreated.value = false
      }
    }
    catch (e) {
      console.error(e)
      status.value = 'FAIL'
    }
  }

  return {
    id,
    status,
    currentEssence,
    serverHandler,
    essenceId,
    currentMaxSort,

    todayDailyCreated,
    today,
    checkCreatedDaily,
  }
}

export function LogicDailyHandler() {
  function formatData(data: DailyEssenceArticleDataRefVO, essenceId: string): DailyItemData {
    const params: DailyItemData = {
      dataRefId: data.dataRefId,
      dataRefType: data.dataRefType,
      // articleType: data.articleType,
      essenceColumn: data.essenceColumn,
      sort: data.sort,
      dataRefImgs: data.dataRefImgs,
      dataRefTitle: data.dataRefTitle,
      userId: data.mediaUserVOList?.[0]?.mediaUserId,
      essenceId,
    }

    if (data.id)
      params.id = data.id
    if (data.sort)
      params.sort = data.sort

    return params
  }
  async function addDataHandler(data: DailyEssenceArticleDataRefVO, essenceId: string) {
    const { setToast, showToast } = useToast(true, 'dialog', { title: '数据添加失败', loading: '数据添加中...', success: '数据添加成功' })
    const params: DailyItemData = formatData(data, essenceId)
    try {
      await addDailyData(params)
      return true
    }
    catch (e) {
      setToast(getErrorMsg(e), 'fail')
      return false
    }
    finally {
      showToast()
    }
  }

  async function updateDataHandler(data: DailyEssenceArticleDataRefVO, essenceId: string) {
    const { setToast, showToast } = useToast(true, 'dialog', { title: '数据更新失败', loading: '数据更新中...', success: '数据更新成功' })
    const params: DailyItemData = formatData(data, essenceId)
    try {
      await updateDailyData([params])
      return true
    }
    catch (e) {
      setToast(getErrorMsg(e), 'fail')
      return false
    }
    finally {
      showToast()
    }
  }

  async function removeDataHandler(id: string) {
    let isConfirm = true
    await vantDialog({
      title: '提示',
      message: '确定删除该条数据？删除后不可恢复。',
      showCancelButton: true,
    }).catch(() => (isConfirm = false))
    if (!isConfirm)
      return false
    const { setToast, showToast } = useToast(true, 'dialog', { title: '数据更新失败', loading: '数据更新中...', success: '数据更新成功' })
    try {
      await deleteDailyData([id])
      return true
    }
    catch (e) {
      setToast(getErrorMsg(e), 'fail')
      return false
    }
    finally {
      showToast()
    }
  }

  async function createDailyHandler() {
    const { setToast, showToast } = useToast(true, 'dialog', { title: '数据更新失败', loading: '数据更新中...', success: '数据更新成功' })
    const params: DailyCreateParams = {
      essenceDataRefDTOS: [],
    }
    try {
      await createDaily(params)
      return true
    }
    catch (e) {
      setToast(getErrorMsg(e), 'fail')
      return false
    }
    finally {
      showToast()
    }
  }

  return {
    createDailyHandler,
    addDataHandler,
    updateDataHandler,
    removeDataHandler,
  }
}
