import { defineStore } from 'pinia'

export const areaMark = ['1/4', '半屏', '3/4', '全屏']
export const areaSize = [20, 50, 70, 100]
export const speedMark = ['极慢', '慢', '适中', '快', '极快']
export const speedSize = (isDesktop: boolean) => isDesktop ? [50, 100, 130, 160, 200] : [20, 40, 60, 80, 100]

export const useBarrageStore = defineStore('WEB_BARRAGE', () => {
  const [showBarrage, toggleBarrage] = useToggle(true)
  const [isAnonymity, toggleIsAnonymity] = useToggle(false)

  const offsetTop = ref(5)
  const area = ref(0)
  const speed = ref(2)
  const fontSize = ref(14)
  return {
    showBarrage,
    toggleBarrage,

    // 是否匿名
    isAnonymity,
    toggleIsAnonymity,

    offsetTop,
    area,
    speed,
    fontSize,
  }
}, {
  persist: [{ storage: persistedState.localStorage, key: 'WEB_BARRAGE' }],
})
