import { isArray } from '@qcwp/utils'
import type { OmitPageParam, PaginationOption } from '@qcwp/common'
import { LOAD_STATUS } from '@qcwp/common'
import { LogicTemplateList } from '../common'
import type { DealerModelParams, DealerModelResponse } from '~~/src/server/modules/car/types'
import { getDealerModel, getDealerModelAll } from '~~/src/server/modules/car'
import type { ModelItem } from '~~/src/components/dealer/hooks/model-item'

export interface DealerManageModelSelect {
  label: string
  value: string
  type: 'group'
  children: (DealerModelResponse & { label: string; value: string })[]
}
export function LogicDealerModel(dealerId: string, option?: PaginationOption) {
  const {
    list: modelData,
    state,
    pagination,
    clientHandler,
    serverHandler,
  } = LogicTemplateList(`DEALER_MODEL_${dealerId}`, getDealerModel, option)
  const formatModelData = computed<ModelItem[]>(() => formatModel(modelData.value) || [])
  const formatSelectModelData = computed<DealerManageModelSelect[]>(() => formatModelSelect(modelData.value) || [])

  function formatModel(data: DealerModelResponse[]): ModelItem[] {
    if (!data || !isArray(data))
      return []

    const newData: ModelItem[] = []
    for (const item of data) {
      newData.push({
        brandId: item.modelVO.carBrandId,
        brandName: item.modelVO.carBrandName,
        vendorId: item.modelVO.carVendorId,
        vendorName: item.modelVO.carVendorName,
        seriesName: item.modelVO.carSeriesName,
        seriesId: item.carSeriesId,
        modelName: `${item.modelVO.carModelName}`,
        modelId: item.modelVO.id,
        guidingPrice: item.modelVO.vendorGuidePrice || item.vendorPrice || '',
        nakedPrice: item.ourPrice || '',
        discountsPrice: item.deprPrice || '',
        promotionalPrice: item.ourPrice || '',
        referenceTotalPrice: item.ourPrice || '',
        reference: null,
        engine: item.modelVO.engine || '',
        grade: item.modelVO.grade || '',
        carName: `${item.modelVO.carVendorName}-${item.modelVO.carSeriesName}-${item.modelVO.carModelName}` || '',
        carImages: item.modelVO.coverImg ? [item.modelVO.coverImg] : [],
        isPromotion: item.isPromotion || false,
        promotionTime: item.promotionTime || '',
      })
    }

    return newData
  }
  function formatModelSelect(data: DealerModelResponse[]): DealerManageModelSelect[] {
    if (!data || !isArray(data))
      return []

    const newData: DealerManageModelSelect[] = []
    const years: string[] = []
    for (const item of data) {
      const index = years.findIndex(v => v === String(item.modelVO.year))
      if (index !== -1) {
        newData[index].children.push({ ...item, label: item.modelVO.carModelName, value: item.modelVO.id })
      }
      else {
        years.push(String(item.modelVO.year))
        newData.push({
          label: String(item.modelVO.year),
          value: String(item.modelVO.year),
          type: 'group',
          children: [{ ...item, label: item.modelVO.carModelName, value: item.modelVO.id }],
        })
      }
    }
    return newData
  }
  return {
    modelData,
    formatModelData,
    formatSelectModelData,
    state,
    pagination,
    clientHandler,
    serverHandler,
  }
}

export function LogicDealerModelAll(dealerId: string) {
  const modelData = ref<DealerModelResponse[]>([])
  const state = ref<LOAD_STATUS>(LOAD_STATUS.INIT)
  async function clientHandler(params: OmitPageParam<DealerModelParams>) {
    state.value = LOAD_STATUS.LOADING
    const { data } = await getDealerModelAll(params) || {}
    if (data)
      modelData.value.push(...data)
    state.value = LOAD_STATUS.END
  }
  const formatModelData = computed<ModelItem[]>(() => formatModel(modelData.value) || [])
  const formatSelectModelData = computed<DealerManageModelSelect[]>(() => formatModelSelect(modelData.value) || [])

  function formatModel(data: DealerModelResponse[]): ModelItem[] {
    if (!data || !isArray(data))
      return []

    const newData: ModelItem[] = []
    for (const item of data) {
      newData.push({
        brandId: item.modelVO.carBrandId,
        brandName: item.modelVO.carBrandName,
        vendorId: item.modelVO.carVendorId,
        vendorName: item.modelVO.carVendorName,
        seriesName: item.modelVO.carSeriesName,
        seriesId: item.carSeriesId,
        modelName: `${item.modelVO.carModelName}`,
        modelId: item.modelVO.id,
        guidingPrice: item.modelVO.vendorGuidePrice || item.vendorPrice || '',
        nakedPrice: item.ourPrice || '',
        discountsPrice: item.deprPrice || '',
        promotionalPrice: item.ourPrice || '',
        referenceTotalPrice: item.ourPrice || '',
        reference: null,
        engine: item.modelVO.engine || '',
        grade: item.modelVO.grade || '',
        carName: `${item.modelVO.carVendorName}-${item.modelVO.carSeriesName}-${item.modelVO.carModelName}` || '',
        carImages: item.modelVO.coverImg ? [item.modelVO.coverImg] : [],
        isPromotion: item.isPromotion || false,
        promotionTime: item.promotionTime || '',
      })
    }

    return newData
  }
  function formatModelSelect(data: DealerModelResponse[]): DealerManageModelSelect[] {
    if (!data || !isArray(data))
      return []

    const newData: DealerManageModelSelect[] = []
    const years: string[] = []
    for (const item of data) {
      const index = years.findIndex(v => v === String(item.modelVO.year))
      if (index !== -1) {
        newData[index].children.push({ ...item, label: item.modelVO.carModelName, value: item.modelVO.id })
      }
      else {
        years.push(String(item.modelVO.year))
        newData.push({
          label: String(item.modelVO.year),
          value: String(item.modelVO.year),
          type: 'group',
          children: [{ ...item, label: item.modelVO.carModelName, value: item.modelVO.id }],
        })
      }
    }
    return newData
  }
  return {
    state,
    modelData,
    formatModelData,
    formatSelectModelData,
    clientHandler,
  }
}
