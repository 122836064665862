import { getSeriesAllList } from '~~/src/server/modules/car'

function createPattern(carSeriesName: string, brandName: string): string[] {
  const newPattern: string[] = []
  brandName = brandName.replace(/(汽车|新能源)/, '')

  if (
    ((carSeriesName.match(/^[a-zA-Z0-9]+/) && carSeriesName.length < 6)
    || (!carSeriesName.match(/^[a-zA-Z0-9]+$/) && carSeriesName.length <= 2))
    && brandName
  )
    newPattern.push(`${brandName}${carSeriesName}`)
  else
    newPattern.push(carSeriesName)

  if (carSeriesName.match(/级$/)) {
    const seriesName = carSeriesName.replace(/级$/, '')
    newPattern.push(`${brandName}${seriesName}`)
    carSeriesName.length > 6 && newPattern.push(seriesName)
  }

  return newPattern
}
export function LogicSeriesAll() {
  const seriesAllMap = ref<Map<string, string>>(new Map())
  const seriesPatternMap = ref<Map<string, string>>(new Map())

  async function serverHandler() {
    const asyncData = await useAsyncData('SERIES_ALL', () => getSeriesAllList())
    if (!asyncData)
      return
    const { data = [], error, refresh } = useNuxtAsyncHandle(asyncData)
    seriesAllMap.value.clear()
    seriesPatternMap.value.clear()
    data.forEach(({ id, carSeriesName }) => seriesAllMap.value.set(id, carSeriesName))
    data.forEach((v) => {
      const { id, carSeriesName, carBrandName = '' } = v
      const pattern = createPattern(carSeriesName, carBrandName)
      if (pattern.length)
        pattern.forEach(v => seriesPatternMap.value.set(v, id))
    })

    return { error, refresh }
  }

  async function getSeriesAllMap() {
    if (!seriesAllMap.value.size)
      await serverHandler()
    else
      return true
  }

  return {
    seriesAllMap,
    seriesPatternMap,
    serverHandler,
    getSeriesAllMap,
  }
}
