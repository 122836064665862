import { defineStore } from 'pinia'
import {
  AUTH_PATH,
  COOKIE_BASE_DOMAIN,
  LoginWayEnum,
  USER_TYPE,
  getHostPagePath,
  goAnyPage,
  goLoginPage,
  homePath,
  loginPath,
} from '@qcwp/common'
import { isClient, isWechat, queryString } from '@qcwp/utils'
import type {
  AccountForm,
  PhoneForm,
  UserInfo,
  WxBindRegisterAutoLoginForm,
  WxLoginForm,
} from './type'
import { authApi } from '~~/src/server'
import type {
  ForgetParams,
  LoginMediaParams,
  LoginParams,
  RegisterParams,
  UserInfoResponse,
} from '~~/src/server/modules/auth/type'

import { NO_LOGIN_TEXT } from '~~/src/common/const'

const USER_INFO_EXPIRES = 1 * 24 * 60 * 60 * 1000 // 1天
const EXPIRES = 20 * 24 * 60 * 60
function generatePersist() {
  const persist = [
    {
      storage: persistedState.localStorage,
      paths: ['USER_INFO'],
    },
    {
      storage: persistedState.sessionStorage,
      paths: ['backPagePath'],
    },
    {
      storage: persistedState.cookiesWithOptions(
        {
          expires: new Date(Date.now() + EXPIRES),
          maxAge: EXPIRES,
          sameSite: 'lax',
          domain: COOKIE_BASE_DOMAIN,
          secure: false,
          path: '/',
        }),
      paths: ['USER_TOKEN', 'NEED_UPDATE'],
    },
  ]

  return persist
}
function generatePersistThird() {
  return [{
    storage: persistedState.sessionStorage,
    paths: ['THIRD_AUTH_SECRET', 'THIRD_INFO_SECRET'],
  }]
}

export const useThird = defineStore('WEB_Third', () => {
  // --------------第三方授权密钥 最大存活 1 * 60 * 60 * 1000---------------------//
  const THIRD_AUTH_SECRET = ref<{ expires: number; data: Record<string, string> }>({
    expires: 0, // 过期时间
    data: {},
  })
  function setAuth(data?: Record<string, string>) {
    if (data)
      THIRD_AUTH_SECRET.value = { expires: Date.now() + 60 * 60 * 1000, data }
    else
      THIRD_AUTH_SECRET.value = { expires: 0, data: {} }
  }
  function checkAuth(keys: string[]) {
    if (THIRD_AUTH_SECRET.value.expires < Date.now())
      return false
    for (const key of keys) {
      if (!Object.keys(THIRD_AUTH_SECRET.value.data).includes(key) || !THIRD_AUTH_SECRET.value.data[key])
        return false
    }
    return true
  }
  // ----------------------------------------------------------------------------//
  // --------------第三方信息密钥 最大存活24 * 60 * 60 * 1000---------------------//
  const THIRD_INFO_SECRET = ref({
    expires: 0, // 过期时间
    data: '',
  })
  function setThirdSecret(data?: string) {
    if (data)
      THIRD_INFO_SECRET.value = { expires: Date.now() + 24 * 60 * 60 * 1000, data }
    else
      THIRD_INFO_SECRET.value = { expires: 0, data: '' }
  }
  // 判断第三方密钥是否有效
  function checkThirdSecret() {
    return THIRD_INFO_SECRET.value.data // && THIRD_INFO_SECRET.value.expires > Date.now()
  }
  // ----------------------------------------------------------------------------//

  return {
    // 第三方授权密钥 最大存活 1 * 60 * 60 * 1000
    THIRD_AUTH_SECRET,
    setAuth,
    checkAuth,

    // 第三方信息密钥 最大存活24 * 60 * 60 * 1000
    THIRD_INFO_SECRET,
    setThirdSecret,
    checkThirdSecret,
  }
}, {
  persist: generatePersistThird(),
})

export const useAuth = defineStore('WEB_AUTH', () => {
  // 用户token
  const USER_TOKEN = ref('')
  const USER_INFO = ref<UserInfo>({
    type: 'GhostUserInfo',
    expires: 0,
  })
  const IS_LOGIN = computed(() => {
    if (!USER_TOKEN.value)
      clearLogin()
    if (!!USER_TOKEN.value && isClient && (USER_INFO.value.type === 'GhostUserInfo' || USER_INFO.value.expires < Date.now()))
      getUserInfo()

    return !!USER_TOKEN.value
  })
  // 是否需要更新USER_INFO
  const NEED_UPDATE = ref<'NULL' | 'web' | 'user'>('NULL')
  let stop = watch(NEED_UPDATE, () => {
    if (!['web', 'NULL'].includes(NEED_UPDATE.value) && isClient && IS_LOGIN.value)
      getUserInfo()
  })
  onUnmounted(() => {
    stop?.()
    stop = null!
  })

  const USER_HEAD = computed(() => {
    if (USER_INFO.value.type !== 'LoginUserInfo')
      return ''
    return USER_INFO.value.mediaAvatar
  })
  const USER_NAME = computed(() => {
    if (!IS_LOGIN.value)
      return NO_LOGIN_TEXT
    if (USER_INFO.value.type === 'GhostUserInfo')
      return '获取用户信息失败'

    return USER_INFO.value.mediaName || USER_INFO.value.phone
  })
  const USER_MEDIA_TYPE = computed(() => {
    if (!IS_LOGIN.value)
      return null

    if (USER_INFO.value.type === 'GhostUserInfo')
      return USER_TYPE.INDIVIDUAL

    return USER_INFO.value.mediaType
  })

  // 登录后回退地址
  const backPagePath = ref(getHostPagePath('web', homePath()))

  /**
   * 更新用户信息
   * @param data
   */
  function setUserInfo(data?: UserInfoResponse) {
    USER_INFO.value = data ? { type: 'LoginUserInfo', ...data, expires: Date.now() + USER_INFO_EXPIRES } : { type: 'GhostUserInfo', expires: 0 }
  }
  /**
   * 更新token
   * @param data
   */
  function setToken(data: string, _isGetUserInfo = false) {
    USER_TOKEN.value = data
    // if (_isGetUserInfo)
    //   getUserInfo()
  }

  // 清除登录信息
  function clearLogin() {
    setToken('')
    setUserInfo()
  }

  // 保存登录回跳地址
  function saveLoginPrePage(backUrl?: string) {
    backPagePath.value = backUrl || '/'
  }
  // 返回登录前的页面
  function backPage() {
    const url = backPagePath.value || homePath()
    // 清空
    backPagePath.value = ''

    goAnyPage(decodeURIComponent(url))
  }
  function durationBackPage(time = 1000) {
    setTimeout(() => {
      vantToast.clear()
      backPage()
    }, time)
  }
  // 需要登录时处理函数
  function needLoginHandle(backUrl?: string) {
    saveLoginPrePage(backUrl ? decodeURIComponent(backUrl) : backUrl)

    vantDialog({
      showCancelButton: true,
      message: '还未登录,请先登录',
      confirmButtonText: '去登录',
      confirmButtonColor: '#3B82F6',
      cancelButtonText: '取消',
      closeOnClickOverlay: true,
    }).then(() => {
      // on confirm
      goLoginPage({ redirect: backUrl })
    }).catch(() => {

    })
  }
  //
  function checkIsLogin() {
    if (IS_LOGIN.value)
      return true
    else
      needLoginHandle(isClient ? window.location.href : '')
  }

  // 判断当前页面是否需要登录
  function checkCurrentPageAuth() {
    const currentUrl = useRoute().path
    let needLogin = false

    for (const path of AUTH_PATH) {
      if (currentUrl === path) {
        needLogin = true
        break
      }
    }

    return needLogin
  }
  /**
   * 判断账号是否被注册
   */
  async function validatorAccount(account: string) {
    try {
      await authApi.validatorAccount(account)
      return true
    }
    catch (error: any) {
      return getErrorMsg(error)
    }
  }
  /**
   * 获取用户信息
   */
  async function getUserInfo() {
    // 通知其他可以范围到cookie的项目(user),下次页面加载时更新user_info
    if (USER_INFO.value.type !== 'GhostUserInfo' && NEED_UPDATE.value === 'NULL')
      NEED_UPDATE.value = 'web'
    // 响应其他端通知的下次页面加载时更新user_info
    if (!['web', 'NULL'].includes(NEED_UPDATE.value))
      NEED_UPDATE.value = 'NULL'
    try {
      const res = await authApi.getUserInfo()
      if (res.data) {
        setUserInfo(res.data)
      }
      else {
        clearLogin()
        vantDialog({
          showCancelButton: true,
          message: '登录已失效，请重新登录',
          confirmButtonText: '去登录',
          confirmButtonColor: '#3B82F6',
          cancelButtonText: '取消',
          closeOnClickOverlay: true,
        }).then(() => {
          // on confirm
          goLoginPage({ redirect: isClient ? window.location.href : '/' })
        }).catch(() => {})
      }
    }
    catch (error: any) {
      console.error('getUserInfo error:', error.message)
      if (error?.message?.includes('code') && Number(JSON.parse(error.message).code) === 402) {
        clearLogin()
        vantDialog.close()
      }

      // 记录错误
      recordError(error)
    }
  }
  /**
   * 刷新token
   */
  async function refreshLoginStatus() {
    try {
      const res = await authApi.loginStatusRefresh()
      setToken(res.data?.access_token)
    }
    catch (error: any) {
      console.error(error)

      // 记录错误
      recordError(error)
      return useNoLoginVantDialog()
    }
  }
  /**
   * 忘记密码
   */
  async function forget(params: ForgetParams) {
    const { setToast, showToast } = useToast(true, 'dialog', {
      title: '密码修改失败',
      loading: '修改中...',
      success: '密码修改成功',
    })

    try {
      await authApi.forget(params)
      setTimeout(() => {
        goAnyPage(loginPath({ redirect: backPagePath.value, type: 'account' }))
      }, 1000)
    }
    catch (error: any) {
      setToast(getErrorMsg(error), 'fail')
      // 记录错误
      recordError(error)
    }
    finally {
      showToast()
    }
  }
  /**
   * 账号注册
   */
  async function register(params: RegisterParams) {
    const thirdAuthStore = useThird()
    const { setToast, showToast } = useToast(true, 'dialog', {
      title: '账号注册失败',
      loading: '注册中...',
      success: '账号注册成功',
    })

    try {
      const res = await authApi.register(params)
      if (thirdAuthStore.checkThirdSecret() && res.code === 200 && res.data) {
        vantToast('注册成功')
        // 自动登录
        wxBindRegisterAutoLogin({
          mediaId: res.data,
          thirdInfoSecret: thirdAuthStore.THIRD_INFO_SECRET.data,
        }, isWechat() ? LoginWayEnum.WX_MP : LoginWayEnum.WX_OPEN)
        return
      }
      setTimeout(() => {
        goAnyPage(loginPath({ redirect: backPagePath.value, type: 'account' }))
      }, 1000)
    }
    catch (error) {
      setToast(getErrorMsg(error), 'fail')
      // 记录错误
      recordError(error)
    }
    finally {
      showToast()
    }
  }
  /**
     * 账号登录
     */
  async function accountLogin(params: AccountForm) {
    const { setToast, showToast } = useLoginToast()
    const p: Partial<LoginParams> = { ...params, loginWay: LoginWayEnum.ACCOUNT }

    // 直接绑定第三方
    const thirdAuthStore = useThird()
    if (thirdAuthStore.checkThirdSecret())
      p.thirdInfoSecret = thirdAuthStore.THIRD_INFO_SECRET.data

    try {
      const res = await authApi.login(p)
      if (res.data?.access_token) {
        setToken(res.data.access_token, true)
        // clear thirdInfoSecret
        thirdAuthStore.setThirdSecret()
        durationBackPage()
        showToast()
      }
    }
    catch (error: any) {
      setToast(getErrorMsg(error), 'fail')
      // 记录错误
      recordError(error)
      showToast()
    }
  }

  /**
   * 手机号登录
   */
  async function phoneLogin(params: PhoneForm) {
    const { setToast, showToast } = useLoginToast()
    const p: Partial<LoginParams> = { ...params, loginWay: LoginWayEnum.PHONE }

    const thirdAuthStore = useThird()
    if (thirdAuthStore.checkThirdSecret())
      p.thirdInfoSecret = thirdAuthStore.THIRD_INFO_SECRET.data

    try {
      const res = await authApi.login(p)
      if (res.data?.access_token) {
        setToken(res.data.access_token, true)
        // clear thirdInfoSecret
        thirdAuthStore.setThirdSecret()
        durationBackPage()
        showToast()
      }
    }
    catch (error: any) {
      setToast(getErrorMsg(error), 'fail')
      // 记录错误
      recordError(error)
      showToast()
    }
  }

  /**
   * 微信授权登录
   * 1. -> 没有绑定媒体账号
   *      -> 注册媒体账号
   *      -> 提示登录已有媒体账号进行绑定
   * 2. -> 绑定多个媒体账号
   *      -> 拿第三方信息密钥请求媒体列表 -> 用户选择媒体(媒体ID) + 第三方信息密钥发送登录请求
   */
  async function wxLogin(params: WxLoginForm, loginWay: LoginWayEnum) {
    const { setToast, showToast } = useLoginToast()

    try {
      const res = await authApi.login({ ...params, loginWay })

      if (res.code === 200 && res.data?.access_token) {
        setToken(res.data.access_token, true)
        durationBackPage()
        showToast()
      }
      return res
    }
    catch (error: any) {
      setToast(getErrorMsg(error), 'fail')
      showToast()
      // 记录错误
      recordError(error)
    }
  }

  /**
   * 微信授权注册自动登录
   */
  async function wxBindRegisterAutoLogin(params: WxBindRegisterAutoLoginForm, loginWay: LoginWayEnum) {
    const { setToast, showToast } = useToast(true, 'dialog', {
      title: '登录失败',
      loading: '正在自动登录...',
      success: '登录成功',
    })

    try {
      const res = await authApi.login({ ...params, loginWay })

      if (res.code === 200 && res.data?.access_token) {
        setToken(res.data.access_token, true)
        durationBackPage()
        showToast()
      }
      return res
    }
    catch (error: any) {
      setToast(getErrorMsg(error), 'fail')
      showToast()
      // 记录错误
      recordError(error)
    }
  }

  /**
   * 登录指定的媒体账号
   * @param params
   * @returns
   */
  async function loginAssignMedia(params: LoginMediaParams, loginWay: LoginWayEnum) {
    const { setToast, showToast } = useLoginToast()

    try {
      const res = await authApi.login({ ...params, loginWay })
      if (res.data?.access_token) {
        setToken(res.data.access_token, true)
        durationBackPage()
        showToast()
      }
    }
    catch (error: any) {
      setToast(getErrorMsg(error), 'fail')
      // 记录错误
      recordError(error)
      showToast()
    }
  }

  /**
     * 退出登录
     */
  async function logout() {
    try {
      await authApi.logout()
    }
    catch (error: any) {
      // 记录错误
      recordError(error)
    }
    finally {
      clearLogin()
      toast('已退出登录')
    }
  }

  return {
    USER_TOKEN,
    NEED_UPDATE,
    USER_INFO,
    IS_LOGIN,
    USER_HEAD,
    USER_NAME,
    USER_MEDIA_TYPE,
    backPagePath,

    needLoginHandle, /* 需要登录时处理函数 */
    checkIsLogin,
    checkCurrentPageAuth, /* 判断当前页面是否需要登录 */
    saveLoginPrePage, /* 保存登录回跳地址 */
    backPage, /* 返回登录前的页面 */
    durationBackPage,

    validatorAccount, /* 判断账号是否被注册 */
    clearLogin,
    getUserInfo, /* 获取用户信息 */
    refreshLoginStatus, /* 刷新token */
    forget, /* 忘记密码 */
    register, /* 注册账号 */
    accountLogin, /* 账号登录 */
    phoneLogin, /* 手机号登录 */
    wxLogin, /* 微信授权登录 */
    loginAssignMedia, /* 登录指定的媒体账号 */
    logout, /* 退出登录 */
  }
}, {
  persist: generatePersist(),
})

// 由于微信公众号时订阅号无登录权限,统一使用开发平台
export function useWxAuth(isBridge = false) {
  if (!isClient)
    return

  const {
    authType,
    authUrl,
    getAuthUrl,
  } = useGetAuthPage(isBridge)

  // 跳转到授权页面获取授权码
  async function goGetCodePage() {
    // 如果初始化获取授权地址失败
    if (!authUrl.value) {
      const { setToast, showToast } = useToast(true, 'dialog', {
        title: '获取授权地址失败',
        loading: '正在获取授权地址...',
        success: '获取授权地址成功',
      })
      try {
        await getAuthUrl()
      }
      catch (error) {
        setToast(`${error}。请刷新后重试`)
        showToast()
      }
    }
    // 截取地址中的code，如果没有code就去微信授权，如果已经获取到code了就直接把code传给后台获取openId
    // test
    // window.open(`/sdk/sdk?${queryUrl(getUrlQuery() || {})}`, '授权登录')
    // pro
    window.open(authUrl.value, '微信授权登录')
  }
  // 提取 url 参数
  function getUrlQuery() {
    if (!location)
      return
    const queries = queryString(location.href)
    return queries
  }
  // 获取当前url地址
  function getThisUrl() {
    if (!location)
      return null
    const result = location.href.split('#')
    if (result.length)
      result[0] = result[0].split('?')[0]
    const url = result[0] + (result?.[1] ?? '')
    return url
  }

  // 判断是否有微信登录code,有则进行登录
  function checkWeChatCode(wxAuthCode: string) {
    if (wxAuthCode)
      return useAuth().wxLogin({ thirdAuthCode: unref(wxAuthCode) }, authType)
    else
      throw new Error('微信授权登录code不存在')
  }

  return {
    authType,
    authUrl,
    goGetCodePage, // 正常跳转授权页面
    getUrlQuery,
    getThisUrl,
    checkWeChatCode,

    getAuthUrl,
  }
}

/**
 * 获取授权地址第三方授权页面地址
 * @param isBridge 是否使用iframe桥架方式获取授权code
 * @returns
 */
export function useGetAuthPage(isBridge = false) {
  // const authType: LoginWayEnum = isWechat() ? LoginWayEnum.WX_MP : LoginWayEnum.WX_OPEN
  const authType: LoginWayEnum = LoginWayEnum.WX_OPEN
  // 回调地址
  // 不走sdk 信息通信页面
  const redirectUrl = (process.env.NODE_ENV === 'production' && !isBridge) ? window.location.href : 'https://www.qcwp.com/sdk/sdk'
  // 第三方授权页面地址
  const authUrl = ref('')
  // 获取授权地址
  async function getAuthUrl() {
    const { data } = await authApi.getWxAuthUrl(redirectUrl)
    if (data)
      authUrl.value = data
  }

  getAuthUrl()
  return {
    authType,
    authUrl,
    getAuthUrl,
  }
}
