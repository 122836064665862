import { isArray } from '@qcwp/utils'
import { LOAD_STATUS } from '@qcwp/common'
import type { DealerSeriesAllResponse } from '~~/src/server/modules/car/types'
import { getDealerSeriesAll } from '~~/src/server/modules/car'

export interface DealerManageVendor {
  vendorName: string
  seriesList: DealerSeriesAllResponse[]
}
export interface DealerManageVendorSelect {
  label: string
  value: string
  type: 'group'
  children: (DealerSeriesAllResponse & { label: string; value: string })[]
}
export function LogicDealerSeriesAll() {
  const seriesAll = ref<DealerSeriesAllResponse[]>([])
  const formatData = ref<DealerManageVendor[]>([])
  const selectData = ref<DealerManageVendorSelect[]>([])
  const loadState = ref<LOAD_STATUS>(LOAD_STATUS.INIT)

  async function serverHandler(dealerId: string) {
    loadState.value = LOAD_STATUS.LOADING

    const asyncData = await useAsyncData('DEALER_SERIES_ALL', () => getDealerSeriesAll({ carDealerId: dealerId }))
    const { data, error } = useNuxtAsyncHandle(asyncData)
    const res = data || []
    seriesAll.value = res
    formatData.value = formatVendor(res)
    selectData.value = formatVendorSelect(res)

    if (error.value)
      loadState.value = LOAD_STATUS.ERROR
    else
      loadState.value = LOAD_STATUS.END
  }

  function formatVendor(data: DealerSeriesAllResponse[]): DealerManageVendor[] {
    if (!data || !isArray(data))
      return []
    const allVendorIds: string[] = []
    const vendorData: DealerManageVendor[] = []
    for (const item of data) {
      const index = allVendorIds.findIndex(v => v === item.carBrandVendorId)
      if (index !== -1) {
        vendorData[index].seriesList.push({ ...item })
      }
      else {
        allVendorIds.push(item.carBrandVendorId)
        vendorData.push({
          vendorName: item.carVendorName,
          seriesList: [{ ...item }],
        })
      }
    }

    return vendorData
  }
  function formatVendorSelect(data: DealerSeriesAllResponse[]): DealerManageVendorSelect[] {
    if (!data || !isArray(data))
      return []
    const allVendorIds: string[] = []
    const vendorData: DealerManageVendorSelect[] = []
    for (const item of data) {
      const index = allVendorIds.findIndex(v => v === item.carBrandVendorId)
      if (index !== -1) {
        vendorData[index].children.push({ ...item, label: item.carSeriesName, value: item.carSeriesId })
      }
      else {
        allVendorIds.push(item.carBrandVendorId)
        vendorData.push({
          label: item.carVendorName,
          value: item.carBrandVendorId,
          type: 'group',
          children: [{ ...item, label: item.carSeriesName, value: item.carSeriesId }],
        })
      }
    }

    return vendorData
  }
  return {
    seriesAll,
    loadState,
    formatData,
    selectData,
    serverHandler,
  }
}
