import { defineStore } from 'pinia'
import { timeFormat } from '@qcwp/utils'
import { ARTICLE_TYPE } from '@qcwp/common'
import type { DailyEssenceArticleDataRefVO, DailyEssenceColumn } from '~~/src/server/modules/article/type'
import { LogicDailyDetail, LogicDailyHandler } from '~~/src/logic/article/daily'

type ExtendsKeys = 'dataRefId' | 'dataRefTitle' | 'dataRefImgs' | 'dataRefType' | 'mediaUserVOList' | 'look' | 'articleType'
export interface DailyCurrentArticle extends Pick<DailyEssenceArticleDataRefVO, ExtendsKeys> {
  isSwiper: boolean
}
export const useDailyStore = defineStore('WEB_DAILY', () => {
  const route = useRoute()

  const {
    createDailyHandler,
    removeDataHandler,
    addDataHandler,
    updateDataHandler,
  } = LogicDailyHandler()

  const {
    essenceId,
    status: loadStatus,
    todayDailyCreated,
    today,
    checkCreatedDaily,
    currentEssence,
    currentMaxSort,
  } = LogicDailyDetail()

  const editorTime = computed(() => timeFormat(currentEssence.value?.numDate, 'MM-DD'))
  const [show, toggleShow] = useToggle(false)
  if (show.value)
    toggleShow()

  const currentArticle = ref<DailyCurrentArticle | null>(null)
  const addOption = ref<{
    show: boolean
    essenceColumn: DailyEssenceColumn
    articleType: ARTICLE_TYPE
    selectImages: string[]
    maxImages: number
  }>({
    show: false,
    essenceColumn: '1',
    articleType: ARTICLE_TYPE.PICTURE,
    selectImages: [] as string[],
    maxImages: 1,
  })

  async function initHandler(options: {
    isReload?: boolean
    checkTime?: boolean
  } = {}) {
    const { isReload = false, checkTime = true } = options || {}
    const time = checkTime ? today !== timeFormat(editorTime.value) : false
    if (!currentEssence.value || time || isReload)
      checkCreatedDaily()
  }
  async function updateDailyHandler() {
    checkCreatedDaily()
  }

  function checkInclude() {
    if (!currentEssence.value)
      return false
    if (!currentArticle.value)
      return false
    return currentEssence.value.articles.some(item => item.dataRefId === currentArticle.value?.dataRefId && item.articleType === currentArticle.value?.articleType)
  }

  function clearHandler(load = false, isShow = false) {
    currentEssence.value = null
    loadStatus.value = 'INIT'
    todayDailyCreated.value = false
    show.value = isShow
    if (load)
      updateDailyHandler()
  }

  async function removeHandler(data: {
    dataRefId: string
    articleType: ARTICLE_TYPE
    essenceColumn: DailyEssenceArticleDataRefVO['essenceColumn']
  }) {
    const index = currentEssence.value?.articles.findIndex(item =>
      item.dataRefId === data.dataRefId
      && item.articleType === data.articleType
      && item.essenceColumn === data.essenceColumn,
    )
    if (index !== undefined && index !== -1) {
      const id = currentEssence.value?.articles[index]?.id
      let result = !id
      result = (id && await removeDataHandler(id)) || false
      result && currentEssence.value?.articles.splice(index, 1)
    }
  }

  async function addHandler(essenceColumn: DailyEssenceArticleDataRefVO['essenceColumn']) {
    if (!currentArticle.value || !currentEssence.value) {
      vantDialog({
        title: '提示',
        message: '当前文章数据无效，请在对应详情页刷新后重试',
      })
      return
    }
    else if (checkInclude()) {
      return vantDialog({
        title: '提示',
        message: '当前文章已存在，请勿重复添加',
      })
    }

    addOption.value = {
      show: true,
      essenceColumn,
      articleType: currentArticle.value.articleType,
      selectImages: [],
      maxImages: essenceColumn === '2' ? 3 : 1,
    }
  }

  async function updateHandler(data: DailyEssenceArticleDataRefVO) {
    const result = await updateDataHandler(data, essenceId.value)
    if (result) {
      const index = currentEssence.value?.articles.findIndex(item =>
        item.dataRefId === data.dataRefId
        && item.articleType === data.articleType
        && item.essenceColumn === data.essenceColumn,
      )
      if (index !== undefined && index !== -1)
        currentEssence.value?.articles.splice(index, 1, data)
    }
  }
  async function createHandler() {
    const result = await createDailyHandler()
    if (result)
      initHandler({ isReload: true })
  }
  let stops = [
    watch(show, async () => {
      if (['FAIL', 'NULL', 'INIT'].includes(loadStatus.value) && show.value)
        await initHandler({ checkTime: true })

      if (!todayDailyCreated.value && show.value) {
        vantDialog({
          title: '提示',
          message: '今日日报未创建，是否创建？',
          confirmButtonText: '创建',
          cancelButtonText: '取消',
          showCancelButton: true,
        }).then(() => {
          createHandler()
        })
      }
    }),
    watch(() => route.path, () => {
      if (!['news-articleId', 'video-articleId', 'live-articleId'].includes(route.name as string))
        currentArticle.value = null
      addOption.value.show = false
      addOption.value.selectImages = []
    }),
  ]
  onUnmounted(() => {
    stops.forEach(stop => stop())
    stops = null!
  })

  return {
    //
    editorTime,
    // 已选数据展示显示
    show,
    toggleShow,

    // 当前精选数据
    essenceId,
    currentEssence,
    currentMaxSort,
    loadStatus,
    todayDailyCreated,
    today,

    // current view article
    currentArticle,
    addOption,

    // handler
    initHandler,
    updateDailyHandler,
    createHandler,
    checkInclude,
    clearHandler,
    removeHandler,
    addHandler,
    updateHandler,

  }
}, {
  persist: [
    {
      storage: persistedState.localStorage,
      key: 'WEB_DAILY',
      paths: [
        'editorTime',
        'currentEssence',
        'loadStatus',
        'todayDailyCreated',
      ],
    },
  ],
})
