import { isArray, isString } from '@qcwp/utils'
import qs from 'qs'
import type { ComputedRef } from 'vue'
import type { OptionItem } from '~~/src/components/layout/filter/hooks/types'

/**
 * 获取路由参数
 * @param key 路由参数名
 * @param type 路由参数类型string | ['string']
 * @returns ComputedRef<string | string[]>
 */
export function useGetRouteParam<T>(key: string, type?: T, defaultValue?: T): ComputedRef<T> {
  const route = useRoute()
  const param = computed(() => {
    if (type === 'string') {
      const param = route.params[key]
      return (isString(param) ? param : param?.[0]) || defaultValue
    }
    else {
      const param = route.params[key]
      return ((param && isArray(param))
        ? param
        : param ? [param] : []
      ).length || defaultValue
    }
  })
  return param as ComputedRef<T>
}

export function useGetRouteQuery<T>(key: string, type: T, defaultValue?: T): ComputedRef<T> {
  const route = useRoute()
  const param = computed(() => {
    if (type === 'string') {
      const param = route.query[key]
      return (isString(param) ? param : param?.[0]) || defaultValue
    }
    else {
      const param = route.query[key]
      if (param && isArray(param))
        return param
      if (isString(param) && param.includes(','))
        return param.split(',')
      return param ? [param] : defaultValue
    }
  })
  return param as ComputedRef<T>
}

/**
 * 获取路由参数拼接字符串 eg: x_x_x
 * @param DEFAULT_PARAMS 默认路由参数 string[]
 * @param separator 路由参数分割符，eg: x_x_x -> _
 * @returns 路由参数数组 string[]
 */
export function getRouteParam(DEFAULT_PARAMS: string[], separator: string | RegExp = '_') {
  const route = useRoute()
  const param = route.params.paramsString
  let routeParams = (isString(param) && param) ? param.split(separator) : [...DEFAULT_PARAMS]
  if (routeParams.length !== DEFAULT_PARAMS.length)
    routeParams = [...DEFAULT_PARAMS]
  return routeParams
}

export function getRouteQuery(DEFAULT_QUERY: Record<string, string>) {
  const route = useRoute()
  const { query } = route
  const routeQuery = { ...DEFAULT_QUERY }
  for (const key in routeQuery) {
    const value = query[key]
    if (value)
      routeQuery[key] = isArray(value) ? value.join(',') : String(value)
  }
  return routeQuery
}

export function generateQuery(key: string, value: string | number | 'DEFAULT', DEFAULT_QUERY: Record<string, string>) {
  const route = useRoute()
  const query = { ...route.query }
  if (value === 'DEFAULT')
    value = DEFAULT_QUERY[key]
  query[key] = String(value)
  return qs.stringify(query)
}

/**
 * 生成路由参数拼接字符串 eg: x_x_x
 * @param keyIndex number
 * @param value
 */
export function generateParamsString(keyIndex: number | undefined = undefined, value: string | number | 'DEFAULT', DEFAULT_PARAMS: string[]) {
  const routeParams = getRouteParam(DEFAULT_PARAMS)
  if (keyIndex === undefined)
    return routeParams.join('_')
  if (value === 'DEFAULT')
    value = DEFAULT_PARAMS[keyIndex]

  routeParams[keyIndex] = String(value)
  return routeParams.join('_')
}

/**
 * filter获取路由参数
 * @param defaultParams 默认的params参数
 * @param data selectForm
 */
export function generateParams(defaultParams: string[], data: Map<string, string>) {
  const params = [...defaultParams]
  for (const [key, value] of data) {
    const index = Number(key)
    if (isNaN(index))
      continue
    params[index] = value
  }
  return params
}

export function getIndex<T extends string, R extends Record<T, OptionItem>>(key: T, data: R, type: 'query' | 'param' = 'param') {
  // Object.keys(data).includes(key) ? data[key].index : -1
  const k = type === 'param' ? 'index' : 'key'
  return data[key][k]
}

export function checkRouteIsActive(reg: RegExp | string): boolean {
  const route = useRoute()
  const { fullPath } = route
  return !!fullPath.match(reg)
}
