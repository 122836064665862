import { isString } from '@qcwp/utils'
import type { DealerDetailResponse } from '~~/src/server/modules/car/types'
import { getDealerDetail } from '~~/src/server/modules/car'

export function LogicDealerBaseInfo() {
  const dealerDetail = ref<DealerDetailResponse | null>(null)

  async function serverHandler(dealerId: string) {
    if (!dealerId || !isString(dealerId))
      throw new Error(`经销商id错误 ${dealerId}`)

    const asyncData = await useAsyncData(`DEALER_DETAIL_${dealerId}`, () => getDealerDetail(dealerId))
    const { data } = useNuxtAsyncHandle(asyncData)
    dealerDetail.value = data || null
  }

  return {
    dealerDetail,
    serverHandler,
  }
}
