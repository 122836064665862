import { defineStore } from 'pinia'
import type { DictResponse } from '~~/src/server/modules/base/types'

export const useCommonStore = defineStore('WEB_COMMON', () => {
  const DICT_MAPS = reactive<Map<string, DictResponse[]>>(new Map())
  const CONFIG_MAPS = reactive<Map<string, unknown>>(new Map())

  return {
    DICT_MAPS,
    CONFIG_MAPS,
  }
})
