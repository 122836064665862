import { isArray } from '@qcwp/utils'
import type { ModelResponse } from '~~/src/server/modules/car/types'
import { getModelAll } from '~~/src/server/modules/car'
export interface ModelMapItem {
  count: number
  years: string[]
  modelsYears: Record<string, ModelResponse[]>
}
export function LogicModel() {
  const seriesModelListMap = reactive(new Map<string, ModelMapItem | null>())

  function getCurrentSeriesModel(seriesId: string) {
    if (seriesModelListMap.has(seriesId) && seriesModelListMap.get(seriesId))
      return seriesModelListMap.get(seriesId)

    return null
  }
  async function getSeriesModelList(seriesId: string): Promise<ModelMapItem | false> {
    if (seriesModelListMap.has(seriesId) && seriesModelListMap.get(seriesId)?.count)
      return seriesModelListMap.get(seriesId) as ModelMapItem

    seriesModelListMap.set(seriesId, null)
    const { error } = await serverHandler(seriesId) || {}
    return (!error || error.value) ? false : seriesModelListMap.get(seriesId) as ModelMapItem
  }
  async function serverHandler(seriesId: string) {
    if (!seriesId)
      throw new Error(`车系id错误:${seriesId}`)

    try {
      const asyncData = await useAsyncData(`MODEL_${seriesId}`, () => getModelAll({ carSeriesId: seriesId }))
      if (!asyncData)
        return
      const { data = [], error, refresh } = useNuxtAsyncHandle(asyncData)
      const { count, years, modelsYears } = format(data)
      seriesModelListMap.set(seriesId, { count, years, modelsYears } || null)
      return { error, refresh }
    }
    catch (error) {
      // console.error('[error] useSeriesDetail getData:', error)
      recordError(error)
    }
  }

  function format(data: ModelResponse[]) {
    const years: string[] = []
    const modelsYears: Record<string, ModelResponse[]> = {}

    if (!data || !isArray(data))
      return { count: 0, years, modelsYears }

    for (const item of data) {
      const year = item.year ? String(item.year) : '#'
      if (!years.includes(year)) {
        years.push(year)
        modelsYears[year] = []
      }
      modelsYears[year].push(item)
    }
    return {
      count: data.length || 0,
      years: years.sort((a, b) => b.localeCompare(a)),
      modelsYears,
    }
  }

  return {
    seriesModelListMap,
    getCurrentSeriesModel,
    getSeriesModelList,
    serverHandler,
  }
}
